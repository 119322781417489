import { useEffect, useState } from "react";
import { FriendRequests } from "../components/friendRequests";
import { FriendsCurrent } from "../components/friendsCurrent";
import { useNavigate } from "react-router-dom";
import FriendSearchBlock from "../components/FriendSearchBlock";

function Connections() {
  const navigate = useNavigate();
  const [friendSearch, setFriendSearch] = useState(null);
  const [searchResults, setsearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const onSearchChange = (friend) => {
    setFriendSearch(friend);
  };

  useEffect(() => {
    if (friendSearch && friendSearch.length >= 3) {
      setLoading(true);
      fetch(`${baseUrl}/usersearch/?searchterm=${friendSearch}`)
        .then((res) => res.json())
        .then((data) => {
          setsearchResults(data.users);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching user search results:", error);
        });
    } else {
      // console.log("friendSearch length is less than 3. Skipping fetch.");
    }
  }, [friendSearch]);

  return (
    <>
      <section>
        <h1>Coming Soon!</h1>
        <p>
          Below is justa glimpse of what is coming - Connect with friends and
          share memories!{" "}
        </p>
        <button onClick={() => navigate(`/app/account/BigFatDesigns`)}>
          view BigFatDesigns
        </button>
        <button onClick={() => navigate(`/app/account/PaulKerns`)}>
          view PaulKerns
        </button>
        <p></p>
        <form>
          <label>Search:</label>
          <input
            value={friendSearch}
            onChange={(e) => onSearchChange(e.target.value)}
          ></input>
        </form>
        {loading ? (
          "Loading..."
        ) : (
          <div className="FriendSearchContainer">
            {searchResults.map((friend) => (
              <FriendSearchBlock friend={friend} />
            ))}
          </div>
        )}
        <p></p>
        <FriendRequests />
        {/* <FriendsCurrent /> */}
      </section>
    </>
  );
}

export default Connections;
