import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { FriendBlock } from "./FriendBlock";

export function FriendRequests() {
  const { user } = useAuth();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [loading, setLoading] = useState(false);

  const [friendReqs, setFriendReqs] = useState([]);
  const [yourFriendReqs, setYourFriendReqs] = useState([]);
  const [currentFriends, setCurrentFriends] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetch(`${baseUrl}/friends/${user.CustUUID}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.friendReqs.length > 0) {
          setFriendReqs(data.friendReqs);
        }

        if (data.userReqs.length > 0) {
          setYourFriendReqs(data.userReqs);
        }

        if (data.currentFriends.length > 0) {
          setCurrentFriends(data.currentFriends);
        }
        //console.log(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user search results:", error);
      });
  }, []);

  return (
    <>
      <h2>Connection Requests</h2>

      {loading ? (
        "Loading..."
      ) : (
        <>
          <div className="FriendRequest">
            <h3>Friend Requests</h3>
            {friendReqs.length > 0 ? (
              friendReqs.map((friendRequest) => (
                <FriendBlock
                  friend={friendRequest}
                  type="FriendRequest"
                  uuid={friendRequest.RequesterCustUUID}
                  key={friendRequest.friendid}
                />
              ))
            ) : (
              <h4>No friend requests found</h4>
            )}

            <h3>Your Requests</h3>
            {yourFriendReqs.length > 0 ? (
              yourFriendReqs.map((userRequest) => (
                <FriendBlock
                  friend={userRequest}
                  type="UserRequest"
                  uuid={userRequest.FriendCustUUID}
                  key={userRequest.friendid}
                />
              ))
            ) : (
              <h4>No friend requests found</h4>
            )}
          </div>
          <div className="CurrentFriends">
            <h3>Current Friends</h3>
            {currentFriends.length > 0 ? (
              currentFriends.map((myFriends) => (
                <FriendBlock
                  friend={myFriends}
                  type="Current"
                  uuid={myFriends.FriendCustUUID}
                  key={myFriends.friendid}
                />
              ))
            ) : (
              <h4>No friends found</h4>
            )}
          </div>
        </>
      )}
      {/* {yourFriendReqs.map((myfriendReqs) => (
        <FriendBlock friend={myfriendReqs} key={myfriendReqs.friendid} />
      ))} */}
      {/* 
      {friendRequests.map((friendReqs) => (
        <FriendBlock friend={friendReqs} type="Current" key={friendReqs.id} />
      ))} */}
    </>
  );
}
