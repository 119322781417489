import { useState } from "react";
import { MutualFriends } from "./MutualFriends";
import { useAuth } from "../contexts/AuthContext";

export function FriendBlock({ friend, type, uuid }) {
  const { user } = useAuth();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imgURL = process.env.REACT_APP_BASE_IMGURL;
  const displayURL = imgURL + "/userfiles/" + uuid;
  const [deleting, setDeleting] = useState(false);

  function handleConfirm() {
    //do it

    setDeleting(true);

    fetch(`${baseUrl}/friends/${user.CustUUID}/${friend.friendid}`, {
      method: "POST",
      body: friend.friendid,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => console.log(data));

    setDeleting(false);
  }

  function handleDeny() {
    //do it
    setDeleting(true);

    fetch(`${baseUrl}/usersearch/?FriendID=` + friend.friendid, {
      method: "PUT",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => console.log(data));
    setDeleting(false);
  }
  function handleCancel() {
    //do it
    setDeleting(true);

    fetch(`${baseUrl}/usersearch/?FriendID=` + friend.friendid, {
      method: "DELETE",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => console.log(data));
    setDeleting(false);
  }

  return (
    <div className="FriendBox">
      <span
        style={{
          backgroundImage: "url(" + displayURL + "/" + friend.ProfilePic + ")",
        }}
      ></span>
      <h3>
        {friend.FirstName} {friend.LastName}
      </h3>
      {/* <MutualFriends
        CommonFriends={friend.CommonFriends}
        friends={friend.Friends}
      /> */}

      {type === "Current" && (
        <>
          <button className="BTN_Secondary">Delete</button>
        </>
      )}
      {type === "UserRequest" && (
        <>
          <button className="BTN_Standard" onClick={handleCancel}>
            Cancel Request
          </button>
        </>
      )}
      {type === "FriendRequest" && (
        <>
          <button className="BTN_Standard" onClick={handleConfirm}>
            Confirm
          </button>
          <button className="BTN_Secondary" onClick={handleDeny}>
            Deny
          </button>
        </>
      )}
    </div>
  );
}
